import React, { useState } from 'react';
import ContentWrapper from '../components/ContentWrapper';
import SoppelSorteringImage from'../images/portfolio/HogskolenVolda/HeroImage.jpg';
import NRKVideo from'../images/portfolio/NRK/nrk_video.png';
import RotsetHornetImage from '../images/portfolio/Rotsethornet/HeroImage.jpg';
import Skolekinodagen from '../images/portfolio/FilmOgKino/video-thumbnail.jpg';
import FiletSkolenImage from '../images/portfolio/FiletSkolen/HeroImage.jpg';
import CreazaImage from '../images/portfolio/Creaza/HeroImage.jpg';
import AlphabetPlanetImage from '../images/portfolio/Creaza/AlfabetPlaneten/thumbnail.jpg';
import IPR from '../images/portfolio/IPR/tannpuss-2.png';
import Title from '../images/titles/portefoelje.svg';
import Refinansiering from '../images/portfolio/Sparebank1/refinansiering.png';
import RoomOfMirrorsThumbnail from '../images/portfolio/Tristious/room-of-mirrors.png';

//Posters
import DodensPraktikantPoster from '../images/portfolio/DodensPraktikant/poster.png'
import PulverHeksaPoster from '../images/portfolio/PulverHeksa/poster.png'
import VDPoster from '../images/portfolio/ViciousDogs/poster.png'
import SantaIsDone from '../images/portfolio/CrispinGlover/poster.png'
import AlienPlanetsPoster from '../images/portfolio/AlienPlanets/poster.png'

import { Link } from 'gatsby';
import '../styles/portfolio.css';


const Poster = ({projectName, imgSrc}) => {
  return <Link to={`/portfolio/${projectName}`}>
     <div className="max-w-sm sm:max-w-md md:max-w-3xs rounded shadow-md">
      <img className="max-w-sm sm:max-w-md md:max-w-3xs rounded" src={imgSrc} />
    </div>
  </Link>
}

const Films = () => {


    return  <ContentWrapper>
          <div className="w-full flex justify-center">
            <div  className="w-72 text-center  mx-auto mt-16 mb-12" />
          </div>
        
         <div className="mb-8 mx-auto">
        <div className="flex flex-wrap  gap-6 justify-center">
            <div className="flex flex-wrap gap-6 justify-center">
              <Poster imgSrc={DodensPraktikantPoster} projectName={"dodens-praktikant"}/>
              <Poster imgSrc={SantaIsDone} projectName={"crispin-glover"}/>
              <Poster imgSrc={PulverHeksaPoster} projectName={"pulverheksa"}/>
            </div>
            <div className="flex-wrap gap-6 justify-center hidden">
              <Poster imgSrc={VDPoster} projectName={"vicious-dogs"}/>
              <Poster imgSrc={AlienPlanetsPoster} projectName={"alien-planets"}/>
            </div>
            <div>
            
            </div>
            
            
 
          </div>
        </div>      
      </ContentWrapper>

}




export default Films;


